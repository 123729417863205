import React, { useEffect, useState } from "react";
import "@src/scss/App.scss";
import Seo from "@components/seo";
import Layout from "@components/layout";
import ModuleRender from "@components/modules/moduleRender";
import BannerRender from "@components/modules/Banner/BannerRender";
import ArticleText from '@components/articleTextSidebar';
import { Routes } from '@components/routes';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Loader from '@components/loader';
import FsLightbox from 'fslightbox-react';
import ModalPortal from '@components/modal/portal';
import { StarberryIcons } from "@components/icons/index";
import { SwiperSlide } from 'swiper/react';
import Carousel from '@components/carousel';
import ButtonLink from '@components/ButtonLink';
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import { cleanP } from '@components/utils/CleanedHtml';
import PropertySlider from '@components/modules/PropertySlider';
const CarouselBtns = ({ processedImages, pageId, images, openLightbox, lightboxController }) => {
    const sliderConfig = {
        slidesPerView: "auto",
        spaceBetween: 8,
        pagination: false,
        breakpoints: {
            992: {
                spaceBetween: 24
            }
        }
    }

    // Components cannot be custom sources - you need to wrap them with some element, so that is why wrapped with <span>
    const arrImgs = images?.map((img) => {
        return(
            <span>
                <ImageTransform
                    imagesources={img?.url}
                    renderer="srcSet"
                    imagename="area-guides.Gallery.image"
                    attr={{ alt: `` }}
                    imagetransformresult={processedImages}
                    id={pageId}
                />
            </span>
        )
    })

    return(
        <>
            <div className="carousel-buttons">
                <Container fluid="lg">
                    <Row>
                        <Col>
                            <Carousel className={`carousel-buttons-carousel`} {...sliderConfig}>
                                {images.length > 0 &&
                                    <SwiperSlide>
                                        <Button onClick={() => openLightbox()} variant="secondary"><StarberryIcons iconName="galleryIcon" className="bi" />Gallery ({images?.length})</Button>
                                    </SwiperSlide>
                                }
                                <SwiperSlide><ButtonLink manualLink="#area-guides-details-map" variant="secondary" icon="mapEmptyIcon" label={`Location`} /></SwiperSlide>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>
            {images.length > 0 &&
                <ModalPortal>
                    <FsLightbox
                        toggler={lightboxController}
                        sources={arrImgs}
                        type="image"
                    />
                </ModalPortal>
            }
        </>
    )
}




const AreaGuideDetails = (props) => {
 
    const GET_AREAGUIDE_DETAILS = gql`
    query GetAreaGuide($Slug: String!){ 
        areaGuides(where:{Slug: $Slug}) {
            id
    Pagename
    Title
    Slug
    HeaderTemplate
    MapLong
    MapLat
    Gallery {
      id
      url
    }
    imagetransforms
    BannerImage {

      ... on ComponentModulesBannerImage {
        ItemText
          ItemTitle
          VideoUrl
          hideBreadcrumbs
          moduleType
          showGoogleRating
          id
          Image {
            alternativeText
            url
            width
          }
          Buttons {
            btnClass
            func
            icon
            id
            label
            manualLink
            page {
              Slug
                 id
                 Parent {
                     Slug
                 }
                 Parent2 {
                     Slug
                 }
                 Parent3 {
                     Slug
                 }
             }
          }
      }
      ... on ComponentModulesGoogleMaps {
        MapLat
        MapLong
        Zoom
        id
        moduleType
        moduleData {
          sectionSpacing
          moduleId
          moduleClass
          id
          Title
          Text
        }
      }
      ... on ComponentModulesAllProjects {
        moduleData {
          Text
          Title
          id
          moduleClass
          moduleId
          sectionSpacing
        }
        id
        moduleType
        area_guide {
          id
        }
      }
     
      ... on ComponentModulesSectionCta {
        moduleType
        moduleData {
            Text
            Title
            id
            moduleClass
            moduleId
            sectionSpacing
          }
        Button {
          id
          manualLink
          btnClass
          label
          icon
          func
          page {
            Slug
            id
            Parent {
              Slug
            }
            Parent2 {
              Slug
            }
            Parent3 {
              Slug
            }
          }
        }
      }
    }

    SidebarContent {
        ... on ComponentModulesTeamContact {
          moduleData {
            sectionSpacing
            moduleId
            moduleClass
            id
            Title
            Text
          }
          team {
            id
            JobTitle
            Name
            Phone
            Slug
            WhatsApp
            Image {
                alternativeText
                url
                
            }
            Email
        }
          moduleType
          id
        }
        ... on ComponentModulesSocialShare {
          moduleData {
            Text
            Title
            id
            moduleClass
            moduleId
            sectionSpacing
          }
          moduleType
        }
      }
    ArticleContent {
        ... on ComponentModulesNormalText {
          moduleType
          moduleData {
            Text
            Title
            id
            moduleClass
            moduleId
            sectionSpacing
          }
          id
        }
        ... on ComponentModulesIntroText {
          id
          moduleType
          moduleData {
            Text
            Title
            moduleClass
            moduleId
            sectionSpacing
            id
          }
        }
        ... on ComponentModulesImageGrid {
          id
          Images {
            id
            Image {
              alternativeText
              url
            }
          }
          moduleType
          moduleData {
            Text
            Title
            moduleClass
            moduleId
            sectionSpacing
          }
        }
        ... on ComponentModulesBookValuation {
          id
          moduleType
          moduleData {
            sectionSpacing
            moduleId
            moduleClass
            id
            Title
            Text
          }
          Buttons {
            btnClass
            func
            icon
            label
            id
            manualLink
          }
        }
      }


    Metadata {
        title
        description
        image {
            url
        }
    }
           
    }
  }
  `;
    const { loading, error, data } = useQuery(GET_AREAGUIDE_DETAILS, {
      variables: { Slug: props?.slug }
    });
    // console.log(data)
    // console.log(props)
    // const {  areaGuide } = data
    useEffect(() => {
      if(typeof window !== 'undefined'){
          window.scrollTo(0,0)
          // window.addEventListener('contextmenu', function (e) {
          //   e.preventDefault();
          // });
      }
  }, [data])
    const [lightboxController, setLightboxController] = useState(false);
    const openLightbox = () => {
        setLightboxController(!lightboxController);
    }

   
const [isproperty, setproperty] = useState(false)
    
    if (loading) return (
      <Layout loading={loading}>
          <Loader loading={loading} error={error} hideText={true} fullScreen />
      </Layout>
      );

    return (
        data?.areaGuides?.length > 0 ?
        <Layout>
             {data?.areaGuides?.map((Page, i) => {
              const pageurl = typeof window !== 'undefined' ? window.location.pathname : Page?.Slug
               const breadcrumbs = [
                { label: "Buy", url: `${Routes.Buy}`},
                { label: "Area Guides", url: `${Routes.BuyProperty}${Routes.AreaGuides}`},
                { label: Page?.Pagename, url: `${pageurl}` }
            ]
            const manualBtns = {
              ...Page,
              BannerImage: [{
                  ...Page?.BannerImage[0],
                  Buttons: [
                      {
                          id: "1",
                          label: "Gallery",
                          onClick: () => openLightbox()
                      },
                       {
                          id: "2",
                          label: "Projects",
                          manualLink: "#projects"
                      }, 
                      {
                          id: "3",
                          label: "Map",
                          manualLink: "#area-guides-details-map"
                      },
                      {
                        id: "3",
                        label: "Schools",
                        manualLink: "#area-guides-details-map"
                    },
                    {
                      id: "3",
                      label: "Amenities",
                      manualLink: "#area-guides-details-map"
                  },
                      {
                          id: "4",
                          label: "At a Glance",
                          manualLink: "#article-text-sidebar"
                      },
                      isproperty ?
                      {
                          id: "5",
                          label: "Properties",
                          manualLink: "#properties"
                      } : null
                  ]
              }]
          }
           // GGFX
    let processedImages = JSON.stringify({});
    if (Page?.imagetransforms?.Gallery_Transforms) {
        processedImages = Page?.imagetransforms?.Gallery_Transforms;
    }
                                   return (
                                    <>
            <Seo
                title={ Page?.Title+" Area Guide "}
                description={"Know more about "+Page?.Title+" here. Contact one of our real estate brokers for assistance in finding the right properties for you in "+Page?.Title+"."}
                image={Page?.Metadata?.image?.url ? Page.Metadata.image.url : Page?.Gallery[0]?.url}
            />
            <BannerRender
                page={manualBtns}
                breadcrumbs={breadcrumbs}
                isGuide={true}
                onImage={<CarouselBtns images={Page?.Gallery} openLightbox={openLightbox} lightboxController={lightboxController} processedImages={processedImages} pageId={Page?.id} />}
            />
            {Page?.BannerImage &&
                <ModuleRender
                    moduleData={Page?.BannerImage}
                    title={cleanP(Page?.Title)}
                    isPage={"area-guide-detail"}
                    slug={Page?.Slug}
                />
            }
            {Page?.ArticleContent &&
                <ArticleText
                    moduleData={Page?.ArticleContent}
                    sidebarData={Page?.SidebarContent}
                />
            }
            
            <PropertySlider  setproperty={setproperty} slug={Page?.Slug} areaguidepage={true} area={Page?.Title} />
            
            
             </>
             )
           })}
        </Layout>
    : null);
}

export default AreaGuideDetails
