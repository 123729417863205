import React, { useState, useEffect } from "react";
import "@src/scss/App.scss";
import Seo from "@components/seo";
import Layout from "@components/layout";
// import { graphql } from "gatsby";
import ModuleRender from "@components/modules/moduleRender";
import BannerRender from "@components/modules/Banner/BannerRender";
import ArticleText from '@components/articleTextSidebar';
import { Routes } from '@components/routes';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import FsLightbox from 'fslightbox-react';
import ModalPortal from '@components/modal/portal';
import { StarberryIcons } from "@components/icons/index";
import { SwiperSlide } from 'swiper/react';
import Carousel from '@components/carousel';
import ButtonLink from '@components/ButtonLink';
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import { cleanP } from '@components/utils/CleanedHtml';
import {
    ProjectImageBannerCarouselFragmentGql,
    SectionCtaFragmentGql,
    GoogleMapsFragmentGql,
    PropertySliderFragmentGql,
    AllProjectsFragmentGql,
    TeamContactFragmentGql,
    SocialShareFragmentGql,
    NormalTextFragmentGql,
    IntroTextFragmentGql,
    ImageGridFragmentGql,
    BookValuationFragmentGql
} from "@components/gql";
import gql from "graphql-tag";
import { useQuery } from '@apollo/client';
// import Stack from 'react-bootstrap/Stack';
import Loader from '@components/loader';
const CarouselBtns = ({ processedImages, pageId, images, openLightbox, lightboxController, brochure }) => {
    const sliderConfig = {
        slidesPerView: "auto",
        spaceBetween: 8,
        pagination: false,
        breakpoints: {
            992: {
                spaceBetween: 24
            }
        }
    }

    // Components cannot be custom sources - you need to wrap them with some element, so that is why wrapped with <span>
    const arrImgs = images?.map((img) => {
        return (
            <span>
                <ImageTransform
                    imagesources={img?.url}
                    renderer="srcSet"
                    imagename="projects.Gallery.image"
                    attr={{ alt: `Project Details - Exclusive Links` }}
                    imagetransformresult={processedImages}
                    id={pageId}
                />
            </span>
        )
    })

    return (
        <>
            <div className="carousel-buttons">
                <Container fluid="lg">
                    <Row>
                        <Col>
                            <Carousel className={`carousel-buttons-carousel`} {...sliderConfig}>
                                {images.length > 0 &&
                                    <SwiperSlide>
                                        <Button onClick={() => openLightbox()} variant="secondary"><StarberryIcons iconName="galleryIcon" className="bi" />Gallery ({images?.length})</Button>
                                    </SwiperSlide>
                                }
                                <SwiperSlide><ButtonLink manualLink="#project-details-map" variant="secondary" icon="mapEmptyIcon" label={`Location`} /></SwiperSlide>
                            {brochure?.url ? <SwiperSlide><ButtonLink manualLink={brochure?.url} variant="secondary" icon="bookIcon" label={`Download Brochure`} /></SwiperSlide>: null}
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>
            {images.length > 0 &&
                <ModalPortal>
                    <FsLightbox
                        toggler={lightboxController}
                        sources={arrImgs}
                        type="image"
                        customAttributes={[
                            {
                            alt: 'Project Detail Images - Exclusive Links'
                            }
                            ]}
                    />
                </ModalPortal>
            }
        </>
    )
}

const ProjectDetails = (props) => {
   
    // const { glstrapi: { project } } = data
    const GET_DETAIL_PROJECT = gql`
    query GetProjectDetail($Slug: String!) {
        projects(where:{Slug: $Slug}){
            id
            Pagename
            Title
            Slug
            HeaderTemplate
            MapLong
            MapLat
            Brochure {
                url
            }
            area_guide {
                id
                Slug
                Pagename
            }
            Gallery {
                id
                url
            }
            imagetransforms
            BannerImage {
                ... on ComponentModulesBannerCarousel {
                    ...ProjectImageBannerCarouselFragmentGql
                }
                ... on ComponentModulesSectionCta {
                    ...SectionCtaFragmentGql
                }
                ... on ComponentModulesGoogleMaps {
                    ...GoogleMapsFragmentGql
                }
                ... on ComponentModulesPropertySlider {
                    ...PropertySliderFragmentGql
                }
                ... on ComponentModulesAllProjects {
                    ...AllProjectsFragmentGql
                }
            }
            SidebarContent {
                ... on ComponentModulesTeamContact {
                    ...TeamContactFragmentGql
                }
                ... on ComponentModulesSocialShare {
                    ...SocialShareFragmentGql
                }
            }
            ArticleContent {
                ... on ComponentModulesNormalText {
                    ...NormalTextFragmentGql
                }
                ... on ComponentModulesIntroText {
                    ...IntroTextFragmentGql
                }
                ... on ComponentModulesImageGrid {
                    ...ImageGridFragmentGql
                }
                ... on ComponentModulesBookValuation {
                    ...BookValuationFragmentGql
                }
            }
            Metadata {
                title
                description
                image {
                    url                    
                }
            }
        }
    }
    ${ProjectImageBannerCarouselFragmentGql}
    ${SectionCtaFragmentGql}
    ${GoogleMapsFragmentGql}
    ${PropertySliderFragmentGql}
    ${AllProjectsFragmentGql}
    ${TeamContactFragmentGql}
    ${SocialShareFragmentGql}
    ${NormalTextFragmentGql}
    ${IntroTextFragmentGql}
    ${ImageGridFragmentGql}
    ${BookValuationFragmentGql}
`;
    const { loading, error, data } = useQuery(GET_DETAIL_PROJECT, {
        variables: { Slug: props?.slug }
    });

    const [lightboxController, setLightboxController] = useState(false);
    const openLightbox = () => {
        setLightboxController(!lightboxController);
    }
    useEffect(() => {
        if(typeof window !== 'undefined'){
            window.scrollTo(0,0)
            // window.addEventListener('contextmenu', function (e) {
            //     e.preventDefault();
            //   });
        }
    }, [data])
    // GGFX

    if (loading) return (
        <Layout loading={loading}>
            <Loader loading={loading} error={error} hideText={true} fullScreen />
        </Layout>
    );
    
    return (
        <Layout bodyClass="project-details">
            {data?.projects?.map((Page, i) => {
                const breadcrumbs = [
                    { label: "Buy", url: `${Routes.Buy}` },
                    { label: "Area Guides", url: `${Routes.BuyProperty}${Routes.AreaGuides}` },
                    { label: `${Page?.area_guide?.Pagename}`, url: `${Routes.BuyProperty}${Routes.AreaGuides}/${Page?.area_guide?.Slug}` },
                    { label: Page?.Pagename, url: `${Routes.BuyProperty}${Routes.AreaGuides}/${Page?.area_guide?.Slug}/${Page?.Slug}` }
                ]
                let processedImages = JSON.stringify({});
                if (Page?.imagetransforms?.Gallery_Transforms) {
                    processedImages = Page?.imagetransforms?.Gallery_Transforms;
                }
                return (
                    <React.Fragment key={i}>
                        <Seo
                            title={Page?.Metadata?.title ? Page.Metadata.title : Page.Title}
                            description={Page?.Metadata?.description ? Page.Metadata.description : null}
                            image={Page?.Metadata?.image?.url ? Page.Metadata.image.url : Page?.Gallery[0]?.url}
                        />
                        <BannerRender
                            page={Page}
                            breadcrumbs={breadcrumbs}
                            onImage={<CarouselBtns images={Page?.Gallery} brochure={Page?.Brochure} openLightbox={openLightbox} lightboxController={lightboxController} processedImages={processedImages} pageId={Page?.id} />}
                        />
                        {Page?.ArticleContent &&
                            <ArticleText
                                page={Page}
                                isProject={true}
                                moduleData={Page?.ArticleContent}
                                sidebarData={Page?.SidebarContent}
                                onImage={<CarouselBtns images={Page?.Gallery} brochure={Page?.Brochure} openLightbox={openLightbox} lightboxController={lightboxController} processedImages={processedImages} pageId={Page?.id} />}
                            />
                        }
                        {Page?.BannerImage &&
                            <ModuleRender
                                moduleData={Page?.BannerImage}
                                projectpage={true}
                                area={Page?.Title}
                                title={cleanP(Page?.Title)}
                                area_guide={Page?.area_guide}
                            />
                        }
                    </React.Fragment>
                )
            })}
        </Layout>
    );
}

export default ProjectDetails

// export const query = graphql`
// query projectQuery($id: ID!) {
//     glstrapi {
//         project(id: $id, publicationState: LIVE) {
//             id
//             Pagename
//             Title
//             Slug
//             HeaderTemplate
//             MapLong
//             MapLat
//             Brochure {
//                 url
//             }
//             area_guide {
//                 id
//                 Slug
//                 Pagename
//             }
//             Gallery {
//                 id
//                 url
//             }
//             imagetransforms
//             BannerImage {
//                 ... on GLSTRAPI_ComponentModulesBannerCarousel {
//                     ...ProjectImageBannerCarouselFragment
//                 }
//                 ... on GLSTRAPI_ComponentModulesSectionCta {
//                     ...SectionCtaFragment
//                 }
//                 ... on GLSTRAPI_ComponentModulesGoogleMaps {
//                     ...GoogleMapsFragment
//                 }
//                 ... on GLSTRAPI_ComponentModulesPropertySlider {
//                     ...PropertySliderFragment
//                 }
//                 ... on GLSTRAPI_ComponentModulesAllProjects {
//                     ...AllProjectsFragment
//                 }
//             }
//             SidebarContent {
//                 ... on GLSTRAPI_ComponentModulesTeamContact {
//                     ...TeamContactFragment
//                 }
//                 ... on GLSTRAPI_ComponentModulesSocialShare {
//                     ...SocialShareFragment
//                 }
//             }
//             ArticleContent {
//                 ... on GLSTRAPI_ComponentModulesNormalText {
//                     ...NormalTextFragment
//                 }
//                 ... on GLSTRAPI_ComponentModulesIntroText {
//                     ...IntroTextFragment
//                 }
//                 ... on GLSTRAPI_ComponentModulesImageGrid {
//                     ...ImageGridFragment
//                 }
//                 ... on GLSTRAPI_ComponentModulesBookValuation {
//                     ...BookValuationFragment
//                 }
//             }
//             Metadata {
//                 title
//                 description
//                 image {
//                     url
//                     url_sharp {
//                         childImageSharp {
//                             resize(width: 1200) {
//                                 src
//                                 height
//                                 width
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
// `